'use strict';

var app = {
    pathToLibsFiles: './assets/libs'
};
window.app = app;
$(document).ready(function (e) {
    //mmenu
    function mmenu() {
        var $windowWidth = $(window).width();
        if ($windowWidth < 991) {
            var _mmenu = $('#main-menu').mmenu({}).data('mmenu');
            $('.hamburger').addClass('d-block');
            $('.hamburger').on('click', function (e) {
                e.preventDefault();
                _mmenu.open();
            });

            if ($('.header-menu').find('.header-menu__email').length) {
                $('#main-menu .mm-panels .mm-navbar').append('<div class="close"></div>');
                $('#main-menu .mm-panels .mm-navbar').append($('.header-menu__email'));
            }
            if ($('.header-menu').find('.header-menu__dropdown:not(.lang-dropdown)')) {
                $('#main-menu .mm-panels .mm-navbar').append($('.header-menu__dropdown:not(.lang-dropdown)'));
            }
            $('#main-menu').find('.close').on('click', function () {
                _mmenu.close();
            });
            $('#main-menu').find('a').on('click', function () {
                _mmenu.close();
            });
        } else {
            $('.hamburger').removeClass('d-block');
        }
    }

    mmenu();
    $(window).resize(function () {
        mmenu();
    });
    var $main_slider = $('.main-slider .main-slider__slides');
    if ($main_slider.length) {
        $main_slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            prevArrow: $('.main-slider .slider-arrows .slider-arrow.prev'),
            nextArrow: $('.main-slider .slider-arrows .slider-arrow.next'),
            dots: true
        });
    }
    var $gallery_slider = $('.gallery__slides');
    var $gallery_slide = $('.gallery__slide');

    if ($gallery_slider.length) {
        $gallery_slider.on('afterChange init', function () {
            $('.slick-cloned').removeClass('prevSlide').removeClass('nextSlide');
            $gallery_slide.removeClass('prevSlide').removeClass('nextSlide');
            for (var i = 0; i < $gallery_slide.length; i++) {
                var $slide = $($gallery_slide[i]);
                if ($slide.hasClass('slick-current')) {
                    $slide.prev().addClass('prevSlide');
                    $slide.next().addClass('nextSlide');
                    break;
                }
            }
        }).on('beforeChange', function () {
            $gallery_slide.removeClass('prevSlide').removeClass('nextSlide');
        }).slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            centerMode: true,
            speed: 300,
            infinite: true,
            variableWidth: true,
            prevArrow: $('.gallery__slider .slider-arrow.prev'),
            nextArrow: $('.gallery__slider .slider-arrow.next'),
            lazyLoad: "progressive",
            responsive: [{
                breakpoint: 1124,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    variableWidth: true
                }
            }]

        });
    }
    var $certifications_slider = $('.certifications__slider');
    if ($certifications_slider.length) {
        $certifications_slider.slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            prevArrow: $('.certifications .container .slider-arrow.prev'),
            nextArrow: $('.certifications .container .slider-arrow.next'),
            dots: false,
            responsive: [{
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: false
                }
            }]

        });
    }
    var $typeComplexList = $('.type-complex .type-complex__list');
    if ($typeComplexList.length) {
        $typeComplexList.mCustomScrollbar({
            callbacks: {
                onScrollStart: function onScrollStart() {
                    $(this).parent().addClass('onScroll');
                },
                onScroll: function onScroll() {
                    $(this).parent().removeClass('onScroll');
                }
            }
        });
    }

    var $parnters__slider = $('.our-partners__slides');
    if ($parnters__slider.length) {
        $parnters__slider.slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            prevArrow: $('.our-partners__slider .slider-arrow.prev'),
            nextArrow: $('.our-partners__slider .slider-arrow.next'),
            dots: true,
            responsive: [{
                breakpoint: 1124,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }, {
                breakpoint: 675,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        });
    }

    var $root = $('html, body');
    var $smooth_scroll = $('a[href^="#"]');
    $smooth_scroll.on('click', function () {
        var href = $.attr(this, 'href');
        $root.animate({
            scrollTop: $(href).offset().top
        }, 800, function () {
            window.location.hash = href;
        });
        return false;
    });

    var $win = $(window);
    var $stat = $('.animated-item');
    $win.on('scroll', function () {
        var scrollTop = $win.scrollTop();

        $stat.each(function () {
            var $self = $(this);
            var prev = $self.offset();
            if (scrollTop - prev.top > -scrollTop / 2) {
                $self.addClass('animated fadeInLeft ');
            }
        });
    }).scroll();

    var $form_elem = $('.form__item input');
    $form_elem.on('focus', function () {
        $(this).parent().addClass('form__item--focus');
    });
    $form_elem.on('focusout', function () {
        if ($(this).val() === '') {
            $(this).parent().removeClass('form__item--focus');
        }
    });
    //phone mask
    var $maskList = $.masksSort($.masksLoad("./assets/libs/js/inputmask/data/phone-codes.json"), ['#'], /[0-9]|#/, "mask");
    if ($maskList) {
        var $maskOpts = {
            inputmask: {
                definitions: {
                    '#': {
                        validator: "[0-9]",
                        cardinality: 1
                    }
                },
                showMaskOnHover: false,
                showMaskOnFocus: false,
                placeholder: "",
                autoUnmask: true,
                clearMaskOnLostFocus: true
            },
            match: /[0-9]/,
            replace: '#',
            list: $maskList,
            listKey: "mask"

        };
    }
    if ($('.phone input').length) {

        $(".phone input").inputmasks($maskOpts);
    }
    $('.phone input').on('focusout', function () {
        $(this).removeAttr('placeholder');
        $(this).inputmask('unmaskedvalue');
        $(this).inputmask('remove');
    });
    var wow = new WOW({
        boxClass: 'animateme', // default

        offset: 0, // default
        mobile: true, // default
        live: true // default
    });
    wow.init();
    $('.call-morePopup').fancybox({
        margin: 0,
        padding: 20,
        maxWidth: 700
    });
});